import './bootstrap';
import './detectjs.js';

import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker';

import { isDateSupported } from './general';

// https://stackoverflow.com/a/4795529/3578036
$.fn.equalizeHeights = function(extra) {
    // Construct an array that contains the height of every element.
    var two = $(this).map((i, e) => {
        return $(e).height();
    });

    // Set the current element's height to the bigger of the two values.
    return this.height(Math.max.apply(this, two.get()) + ~~extra);
}

// Datepicker fallback
if (!isDateSupported()) {
    // Setting a timeout because Vue components are irritating when it comes to
    // doing something outside a Vue component.
    setTimeout(function () {
        $('input[type="date"]').datepicker();
    }, 1500);
}


// Toasts for messages
$(function() {

    let $toasts = $(".toast");
    $toasts.toast({
        delay: 12000
    });
    $toasts.toast('show');
});


